body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5; /* Cor de fundo aplicada a toda a página */
}

.aulas-page {
  padding: 20px;
}

h1 {
  margin: 0 0 20px 0;
}

.aulas-list {
  list-style: none;
  padding: 0;
}

.aula-item {
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.aula-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra */
}

.aula-card h2 {
  margin: 0;
  font-size: 24px;
}

.aula-card h3 {
  margin: 10px 0;
  font-size: 20px;
  color: #555;
}

.aula-card p {
  margin: 5px 0;
  font-size: 16px;
}

.label {
  font-weight: bold;
}

.button-container {
  align-self: flex-end;
  position: flex;
  bottom: 20px;
  right: 20px;
}

@media (max-width: 600px) {
  .aula-card {
    padding-bottom: 60px; /* Adiciona espaço extra na parte inferior para o botão */
  }

  .button-container {
    position: static; /* Remove o posicionamento absoluto */
    margin-top: 10px; /* Adiciona espaço acima do botão */
    text-align: center; /* Centraliza o botão */
  }
}
